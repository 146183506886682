<template>
  <div id="divVideoDetailWrapper">
    <div ref="anchTopScroll"></div>
    <div class="detail-top">
      <div class="video-wrapper">
        <BrandVimeoPlayer
          v-if="mediaData"
          :logger="{ interval: 5 }"
          :data="mediaData"
          :id="mediaData.videoId"
          @timeUpdate="onProgress($event)"
          @end="onProgress($event)"
        ></BrandVimeoPlayer>
      </div>
      <VueSpacer level="20"></VueSpacer>
    </div>

    <VueContentHolder class="detail-bottom" :padding="[20, 20]">
      <VueHeadline v-if="mediaData" level="2" weightLevel="4" class="video-title">{{
        mediaData.title
      }}</VueHeadline>

      <VueSpacer level="20"></VueSpacer>
      <VueHeadline level="2" weightLevel="4">Eğitim Videoları</VueHeadline>
      <VueSpacer level="20"></VueSpacer>
      <div v-for="item in mediaItems" :key="item.id">
        <BrandMediaListItem
          :text="item.title"
          :percentage="item.percentWatched"
          :mediaSource="item"
          @handleRoute="handleVideoRouting(item)"
        ></BrandMediaListItem>
      </div>
    </VueContentHolder>
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import BrandMediaListItem from '@/components/brand/BrandMediaListItem/BrandMediaListItem.vue';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import StorageHelper from '@/utils/storageHelper';
import { Learn } from '@/services/Api/index';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import vimeoUtils from '@/mixins/vimeoUtils.js';

export default {
  name: 'VideoDetail',
  mixins: [vimeoUtils],
  components: {
    VueHeadline,
    VueContentHolder,
    BrandMediaListItem,
    BrandVimeoPlayer,
    VueSpacer,
  },
  data() {
    return {
      mediaData: null,
      mediaItems: [],
    };
  },
  computed: {
    mediaConstants() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
      return { media };
    },
  },
  methods: {
    onProgress(eventData) {
      let index = this.mediaItems.findIndex(x => x.videoId === this.mediaData.videoId);
      this.mediaItems[index].percentWatched = eventData.percent;
      this.mediaItems[index].playbackPosition = eventData.position;
      this.mediaItems[index].viewStatus = eventData.viewStatus;
      this.mediaItems[index].isViewed = eventData.isViewed;
    },
    handleVideoRouting(item) {
      new StorageHelper({
        id: process.env.VUE_APP_STORAGE_VIDEO_DETAIL_KEY,
      }).set(item);
      this.mediaData = null;
      setTimeout(() => {
        this.mediaData = item;
        this.$refs.anchTopScroll.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    },
  },
  created() {
    this.$store.dispatch('app/setOverlayFunction', false);
    let selectedMedia = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_VIDEO_DETAIL_KEY,
    }).get();

    if (selectedMedia && selectedMedia.levelId) {
      Learn.getContentByLevelId(selectedMedia.levelId).then(res => {
        const response = res.data.Data;
        if (response) {
          let mediaArray = response.videos
            .filter(item => item.link && item.link.length > 0)
            .map(item => ({
              videoId: item.id,
              title: item.title,
              levelId: selectedMedia.levelId,
              viewStatus: item.status,
              percentWatched: item.percent,
              playbackPosition: item.playbackPosition,
              isStarting: false,
              isViewed: item.viewCount > 0,
              embedVideo: item.link.replace(/\r?\n|\r/g, '').trim(),
            }));
          this.mediaData = mediaArray.find(x => x.videoId == selectedMedia.videoId);
          this.mediaItems = this.getVideoThumbs(mediaArray);
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.video-title {
  padding-bottom: palette-space-level('20');
  border-bottom: 1px solid palette-color-level('grey', '20');
}
.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  .video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
